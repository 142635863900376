import initialState from './state'

const datatables = (state = initialState, action) => {
  switch (action.type) {
    case 'modal_customer_transaction_game':
      return {
        ...state,
        modal_customer_transaction_game: action.data
      }
    case 'modal_customer_activity':
      return {
        ...state,
        modal_customer_activity: action.data
      }
    case 'modal_customer_transaction_credit':
      return {
        ...state,
        modal_customer_transaction_credit: action.data
      }
    case 'modal_customer_invite_friend':
      return {
        ...state,
        modal_customer_invite_friend: action.data
      }
    case 'modal_customer_promotion':
      return {
        ...state,
        modal_customer_promotion: action.data
      }
    case 'modal_customer_shop':
      return {
        ...state,
        modal_customer_shop: action.data
      }
    case 'modal_customer_wallets':
      return {
        ...state,
        modal_customer_wallets: action.data
      }
    case 'modal_customer_banks':
      return {
        ...state,
        modal_customer_banks: action.data
      }
    // cleardata
    case 'clear_modal_customer_list':
      return {
        ...state,
        modal_customer_transaction_game: action.data,
        modal_customer_activity: action.data,
        modal_customer_transaction_credit: action.data,
        modal_customer_invite_friend: action.data,
        modal_customer_promotion: action.data,
        modal_customer_shop: action.data,
        modal_customer_wallets: action.data,
        modal_customer_banks: action.data
      }
    //payment
    case 'bank_bot_list':
      return {
        ...state,
        bank_bot_list: action.data
      }
    case 'deposit_list':
      return {
        ...state,
        deposit_list: action.data
      }
    case 'withdraw_list':
      return {
        ...state,
        withdraw_list: action.data
      }

    //customer
    case 'customer_list':
      return {
        ...state,
        customer_list: action.data
      }

    //report
    case 'win_loss_list':
      return {
        ...state,
        win_loss_list: action.data
      }
    case 'win_loss_game_list':
      return {
        ...state,
        win_loss_game_list: action.data
      }
    case 'win_loss_customer_list':
      return {
        ...state,
        win_loss_customer_list: action.data
      }
    case 'commission_list':
      return {
        ...state,
        commission_list: action.data
      }
    case 'commission_category_list':
      return {
        ...state,
        commission_category_list: action.data
      }
    case 'cashback_list':
      return {
        ...state,
        cashback_list: action.data
      }
    case 'cashback_category_list':
      return {
        ...state,
        cashback_category_list: action.data
      }
    case 'game_cmap_list':
      return {
        ...state,
        game_cmap_list: action.data
      }
    case 'deposit_again_list':
      return {
        ...state,
        deposit_again_list: action.data
      }
    //setting
    case 'game_list':
      return {
        ...state,
        game_list: action.data
      }
    case 'image_game_list':
      return {
        ...state,
        image_game_list: action.data
      }
    case 'banner_slide_list':
      return {
        ...state,
        banner_slide_list: action.data
      }
    case 'banner_slide_event_list':
      return {
        ...state,
        banner_slide_event_list: action.data
      }
    case 'banner_slide_hot_list':
      return {
        ...state,
        banner_slide_hot_list: action.data
      }
    case 'customer_referral_summary':
      return {
        ...state,
        customer_referral_summary: action.data
      }
    case 'setting_contact_list':
      return {
        ...state,
        setting_contact_list: action.data
      }
    case 'organization_agentbank_list':
      return {
        ...state,
        organization_agentbank_list: action.data
      }
    //lottery
    case 'admin_round_list':
      return {
        ...state,
        admin_round_list: action.data
      }
    case 'lottery_ticket':
      return {
        ...state,
        lottery_ticket: action.data
      }
    case 'lottery_ticket_number':
      return {
        ...state,
        lottery_ticket_number: action.data
      }
    case 'lottery_special':
      return {
        ...state,
        lottery_special: action.data
      }
    case 'lottery_unlimit':
      return {
        ...state,
        lottery_unlimit: action.data
      }
    case 'lottery_price':
      return {
        ...state,
        lottery_price: action.data
      }
    case 'lottery_summary':
      return {
        ...state,
        lottery_summary: action.data
      }
    case 'lottery_number':
      return {
        ...state,
        lottery_number: action.data
      }
    case 'lottery_unlimit':
      return {
        ...state,
        lottery_unlimit: action.data
      }
    case 'lottery_bill':
      return {
        ...state,
        lottery_bill: action.data
      }
    case 'lottery_summary_user':
      return {
        ...state,
        lottery_summary_user: action.data
      }
    case 'lottery_winloss':
      return {
        ...state,
        lottery_winloss: action.data
      }
    case 'customer_list':
      return {
        ...state,
        customer_list: action.data
      }
    case 'lottery_config':
      return {
        ...state,
        lottery_config: action.data
      }
    //clear data
    case 'clear_lottery':
      return {
        ...state,
        lottery_unlimit: action.data,
        lottery_special: action.data
      }
    default:
      return { ...state }
  }

}

export default datatables
