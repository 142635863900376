// ** Initial State
const initialState = {
  data: [],
  bank: [],
  bankcategory: []
}

const agent = (state = initialState, action) => {
  switch (action.type) {
    case 'AGENT_GET_DATA':
      return {
        ...state,
        data: action.data
      }
    case 'AGENT_BANK':
      return {
        ...state,
        bank: action.data
      }
    case 'AGENT_GATEGORY':
      return {
        ...state,
        data: action.data
      }
    case 'AGENT_BANK_GATEGORY':
      return {
        ...state,
        bankcategory: action.data
      }
    default:
      return { ...state }
  }
}

export default agent