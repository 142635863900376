// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
  dataReFig: [],
  close: false
}

const report = (state = initialState, action) => {
  switch (action.type) {
    case 'GAME_REPORT_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'POPUP_SET_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'CONTACT_SET_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'BANK_RESULT_REPORT_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'COM_REPORT_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'COM_REPORT_GET_DATA_FOR_EXCEL':
      return {
        ...state,
        dataforexcel: action.data
      }
    case 'RESULTFIG_VIEW_DATA':
      return {
        ...state,
        dataReFig: action.data
      }
      case 'CHECK_RESULT':
        return {
          ...state,
          close: action.close
        }
    default:
      return { ...state }
  }
}

export { report }
