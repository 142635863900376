
// ** Initial State
const initialState = {
  // data: [],
  // total: 1,
  modal: {
    open: true,
    id: 0,
    page: 0
  },
  editModal: {
    item: {},
    show: false
  },
  deleteModal: {
    item: {},
    show: false
  },
  banks: {
    data: [],
    total: 0
  },
  banks_log: {
    data: []
  },
  games: {
    data: [],
    total: 0
  },
  promotions: {
    data: [],
    total: 0
  },
  friends: {
    data: [],
    total: 0
  },
  credits: {
    data: [],
    total: 0
  },
  wallet: {
    data: [],
    total: 0
  },
  shop: {
    data: [],
    total: 0
  },
  activity: {
    data: [],
    total: 0
  },
  customer_balance: {
    data: [],
    total: 0
  }

}

export const modalCustomer = (state = initialState, action) => {
  switch (action.type) {
    case 'MODAL_CUSTOMER':
      return {
        ...state,
        modal: action.data
      }
    case 'MODAL_CUSTOMER_BANK':
      return {
        ...state,
        banks: action.data,
        banks_log: action.data.log
      }
    default:
      return { ...state }
  }
}


export const gameTransaction = (state = initialState, action) => {
  switch (action.type) {
    case 'GAME_TRANSACTION_GET_DATA':
      return {
        ...state,
        games: action.data
      }
    default:
      return { ...state }
  }
}

export const promotionInfo = (state = initialState, action) => {
  switch (action.type) {
    case 'PROMOTION_GET_DATA':
      return {
        ...state,
        promotions: action.data
      }
    default:
      return { ...state }
  }
}

export const inviteFriendInfo = (state = initialState, action) => {
  switch (action.type) {
    case 'INVITE_FRIEND_GET_DATA':
      return {
        ...state,
        friends: action.data
      }
    default:
      return { ...state }
  }
}

export const creditTransaction = (state = initialState, action) => {
  switch (action.type) {
    case 'CREDIT_TRANSACTION_GET_DATA':
      return {
        ...state,
        credits: action.data
      }
    default:
      return { ...state }
  }
}

export const modalCustomerEditBank = (state = initialState, action) => {
  switch (action.type) {
    case 'CUSTOMER_EDIT_BANK_DATA':
      return {
        ...state,
        editModal: action.data
      }
    default:
      return { ...state }
  }
}

export const modalCustomerDeleteBank = (state = initialState, action) => {
  switch (action.type) {
    case 'CUSTOMER_DELETE_BANK_DATA':
      return {
        ...state,
        editModal: action.data
      }
    default:
      return { ...state }
  }
}

export const walletList = (state = initialState, action) => {
  switch (action.type) {
    case 'WALLET_LIST_GET_DATA':
      return {
        ...state,
        wallet: action.data
      }
    default:
      return { ...state }
  }
}

export const shopList = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOP_LIST_GET_DATA':
      return {
        ...state,
        shop: action.data
      }
    default:
      return { ...state }
  }
}

export const activityList = (state = initialState, action) => {
  switch (action.type) {
    case 'ACTIVITY_LIST_GET_DATA':
      return {
        ...state,
        activity: action.data
      }
    default:
      return { ...state }
  }
}

export const customerBalance = (state = initialState, action) => {
  switch (action.type) {
    case 'CUSTOMER_BALANCE_GET_DATA':
      return {
        ...state,
        customer_balance: action.data
      }
    default:
      return { ...state }
  }
}