// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  selectedUser: null
}

const organization = (state = initialState, action) => {
  switch (action.type) {
    case 'EMPLOYEE_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'PAYMENT_BANKS_ACCOUNT_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'PAYMENT_BANKS_TRANSACTION_GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_EMPLOYEE':
      return { ...state, selectedUser: action.selectedUser }
    case 'GET_EMPLOYEE_LOGIN_HISTORY':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    default:
      return { ...state }
  }
}


const employeeList = (state = initialState, action) => {
  switch (action.type) {
    case 'EMP_LIST':
      return {
        ...state,
        data: action.data
      }
    default:
      return { ...state }
  }
}

export { organization, employeeList }
